// Well hello there. Looks like we don't have any Javascript.
// Maybe you could help a friend out and put some in here?
// Or at least, when ready, this might be a good place for it.

// import priorityNav from 'priority-nav';

import $ from 'jquery'
import throttle from 'lodash.throttle'
// var PhotoSwipe = require('photoswipe')
// var PhotoSwipeUIDefault = require('photoswipe/dist/photoswipe-ui-default')

// Core CSS file
import '!style-loader!css-loader!photoswipe/dist/photoswipe.css'
// Skin CSS file (styling of UI - buttons, caption, etc.)
//      In the folder of skin CSS file there are also:
//      - .png and .svg icons sprite,
//      - preloader.gif (for browsers that do not support CSS animations)
import '!style-loader!css-loader!photoswipe/dist/default-skin/default-skin.css'
;(function ($) {
  var w = window
  var d = document
  var b = d.body
  var h = d.querySelector('.SiteHeader')
  var stuck = false
  var stickPoint = getDistance()

  function getDistance() {
    var topDist = h.offsetTop
    return topDist
  }

  var footer = $('.Footer')
  var footerOffset = 0

  var ensureFooterHasOffset = function () {
    var klaro = $('#klaro .cookie-notice')
    footerOffset = (klaro && klaro.outerHeight()) || 0

    footer.css('marginBottom', `${footerOffset}px`)
  }

  w.addEventListener('scroll', throttle(ensureFooterHasOffset, 250))

  var navBar = document.querySelector('.SiteNavigation__NavBar')
  var toggle = document.querySelector('.NavigationToggle')

  var toggleMenu = function (el) {
    var duration = 150
    var $el = $(el)
    var $toggle = $(toggle)

    if ($el.hasClass('expanded')) {
      // Close
      $el.slideUp({
        duration: duration,
        start: function () {
          $toggle.attr('aria-expanded', false)
        },
        done: function () {
          $el.removeClass('expanded')
          $el.css('display', '')
        },
      })
    } else {
      // Show
      $el.slideDown({
        duration: duration,
        start: function () {
          $toggle.attr('aria-expanded', true)
        },
        done: function () {
          $el.addClass('expanded')
          $el.css('display', '')
        },
      })
    }
  }

  toggle.addEventListener('click', function (e) {
    toggleMenu(navBar)
  })

  // Smooth Scroll
  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, '') ==
          this.pathname.replace(/^\//, '') &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash)
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']')
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault()
          $('html, body').animate(
            {
              scrollTop: target.offset().top,
            },
            800,
            function () {
              // Callback after animation
              // Must change focus!
              var $target = $(target)
              $target.focus()
              if ($target.is(':focus')) {
                // Checking if the target was focused
                return false
              } else {
                $target.attr('tabindex', '-1') // Adding tabindex for elements not focusable
                $target.focus() // Set focus again
              }
            }
          )
        }
      }
    })

  // w.addEventListener('scroll', function() {
  //   var distance = getDistance() - window.pageYOffset;
  //   var offset = window.pageYOffset;
  //   // readout.innerHTML = stickPoint + '   ' + distance + '   ' + offset + '   ' + stuck;
  //   if (distance <= 0 && !stuck) {
  //     h.classList.add('SiteHeader--is-fixed');
  //     stuck = true;
  //   } else if (stuck && offset <= stickPoint) {
  //     h.classList.remove('SiteHeader--is-fixed');
  //     stuck = false;
  //   }
  // });

  // var e = document.querySelector('.SiteNavigationToggle');
  // var t = document.querySelector('.SiteNavigation');
  // var a = e.getAttribute('aria-expanded');

  // function getMaxHeight(t) {
  //   // Source: http://n12v.com/css-transition-to-from-auto/
  //   var prevHeight = t.style.height;
  //   t.style.height = 'auto';
  //   var maxHeight = getComputedStyle(t).height;
  //   t.style.height = prevHeight;
  //   t.offsetHeight; // force repaint
  //   return maxHeight;
  // }

  // /* Open the Menu after clicking the menu button
  //  Only if the menu is current collapsed that is.
  // */

  // e.onclick = function() {
  //   if (a == 'false') {
  //     t.classList.remove('collapse');
  //     t.classList.add('collapsing');
  //     e.classList.remove('collapsed');
  //     e.setAttribute('aria-expanded', true);
  //     t.style.height = getMaxHeight(t);
  //   }
  // };

  // /* Collapse the menu after clicking anywhere, not just the menu button.
  //  Only if the menu is already expanded, of course.
  // */

  // document.onclick = function() {
  //   if (a == 'true') {
  //     t.classList.remove('collapse');
  //     t.classList.remove('in');
  //     t.classList.add('collapsing');
  //     e.classList.add('collapsed');
  //     e.setAttribute('aria-expanded', false);
  //     t.style.height = getComputedStyle(t).height;
  //     t.offsetHeight, (t.style.height = '0px');
  //   }
  // };

  // // Transition Handling
  // t.addEventListener('transitionend', function() {
  //   t.classList.remove('collapsing');
  //   t.classList.add('collapse');

  //   // Check whether the element is unhidden
  //   if (t.style.height !== '0px') {
  //     t.classList.add('in');
  //     t.style.height = 'auto';
  //   }

  //   // Get 'aria-expanded' state
  //   a = e.getAttribute('aria-expanded');
  // });
})($)
